<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <b-card title="Importer des renders">

    <b-form-input
             
      id="basicInput"
      type="text"
      v-model="folder_name"
 
      placeholder="Nom dossier"
             
    />
    <br />
    <b-button
      @click="importFolder"
      variant="primary"
    >
     Importer le dossier
    </b-button>
   
      <br /><br />
      {{ message_import }}
    <!-- table -->
    

  </b-card>

  <b-card title="Supprimer des renders">

    <b-form-input
      
      id="basicInput"
      type="text"
      v-model="product_id"

      placeholder="ID produit"
            
    />
    <br />

  <b-button
  @click="deleteRenders"
    variant="primary"
  >
  Supprimer les renders pour cette lunette
  </b-button>

  <br /><br />
  {{ message_delete }}
<!-- table -->


</b-card>
</b-overlay>
</template>

<script>
import {BModal,BOverlay, BButton, VBModal, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton
  },
  data() {
    return {
      tints:[],
      pageLength: 3,
      product_id:null,
      totalRows:0,
      folder_name:'',
      currentPage:1,
      dir: false,
      message_import:'',
      message_delete:'',
      loading:true,
      selectedItem:null,
      columns: [
        {
          label: 'Nom',
          field: 'name',
        },
        {
          label: 'Prix',
          field: 'price',
        },
        {
          label: 'Action',
          field: 'action',
        }
        
      ],
      rows: [],
      searchTerm: '',

    }
  },
  watch:{
    currentPage:async function(){
      this.loading=true
      let offset=this.currentPage*this.pageLength
      let tints=await APIRequest.list('tints', null, null, this.pageLength,offset)
      this.tints=tints[0]
      this.loading=false
    }
  },
  methods:{
    async importFolder()
    {
      this.loading=true

      try {

        let folder=await APIRequest.import_renders_from_folder(this.folder_name)

        this.message_delete=""
        this.message_import="Les renders ont bien été importés !"

        this.loading=false
        
        
      } catch (error) {

        this.message_delete=""
        this.message_import="Une erreur est survenue."

        this.loading=false
        
      }

      
      
    },

    async deleteRenders()
    {
      this.loading=true

      try {

        let folder=await APIRequest.remove_renders(this.product_id)

        this.message_delete="Les renders ont bien été supprimés !"
        this.message_import=""

        this.loading=false
        
        
      } catch (error) {

        this.message_delete="Une erreur est survenue."
        this.message_import=""

        this.loading=false
        
      }

      
      
    },

    async deleteTint(id)
    {
      this.loading=true
      try {
        await APIRequest.delete('tints',id)
        let tints=await APIRequest.list('tints')
        this.tints=tints[0]
        this.totalRows=tints[1]
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'La teinte a été supprimée !',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        
      } catch (error) {
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        
      }
      
    }
  },
  async mounted()
  {
    let tints=await APIRequest.list('tints')
    this.tints=tints[0]
    this.totalRows=tints[1]
    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
